
import { defineComponent, Prop, PropType } from "vue";
// swiper引用
import Swiper, {
  Autoplay,
  EffectCoverflow,
  EffectCube,
  Pagination,
  Navigation,
  Zoom,
} from "swiper";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.scss";
Swiper.use([
  Autoplay,
  EffectCoverflow,
  EffectCube,
  Pagination,
  Navigation,
  Zoom,
]);

export default defineComponent({
  name: "Image-Lightbox-Modal",
  props: ["info", "id"],
  emits: ["closeImageLightBoxModal"],
  data() {
    return {
      count: 1 as number,
      isPosting: false,
      previousIndex: 0,
      activeIndex: 0,
      swiper: null as any,
    };
  },
  methods: {
    closeModal() {
      this.$emit("closeImageLightBoxModal");
    },
    swiperBind() {
      this.count = 1;
      this.swiper = null;
      this.swiper = new Swiper(`.swiper-modal-${this.id}`, {
        zoom: true,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
          hideOnClick: false,
        },
        grabCursor: false,
        on: {
          slideChange: function () {
            // 當滑動時觸發
            const previousIndex = (this as any).previousIndex; // 前一個索引值
            const activeIndex = (this as any).activeIndex; // 當前索引值
            const currentPage = (this as any).activeIndex + 1;
            countCal(currentPage);
          },
          touchStart: function (event: any) {
            if (event.touches.length > 1) {
              event.preventDefault();
            }
          },
          touchMove: function (event: any) {
            if ((this as any).zoom && (this as any).zoom.scale !== 1) {
              (this as any as any).allowSlidePrev = false;
              (this as any).allowSlideNext = false;
            }
          },
          touchEnd: function () {
            if ((this as any).zoom && (this as any).zoom.scale !== 1) {
              (this as any).allowSlidePrev = true;
              (this as any).allowSlideNext = true;
            }
          },
        },
      });

      let countCal = (currentPage: number) => {
        this.count = currentPage;
      };
      // console.log(this.id)
    },
    selectSwiperImages() {
      let targets = document.getElementsByClassName(
        `post-img-${this.id}`
      )! as HTMLCollectionOf<HTMLDivElement>;
      for (let i = 0; i < targets.length; i++) {
        targets[i].style.transformOrigin = "0px 0px";
      }

      let target = document.getElementsByClassName(
        `swiper-zoom-container-${this.id}`
      )! as HTMLCollectionOf<HTMLDivElement>;
      for (let i = 0; i < target.length; i++) {
        target[i].style.transform = "translate3d (0px, 0px, 0px);";
      }
    },
  },
  mounted() {
    if (this.info) {
      this.swiper = new Swiper(`.swiper-modal-${this.id}`, {
        zoom: true,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
          hideOnClick: false,
        },
        grabCursor: false,
        on: {
          slideChange: function () {
            // 當滑動時觸發
            // console.log("滑動")
            const previousIndex = (this as any).previousIndex; // 前一個索引值
            const activeIndex = (this as any).activeIndex; // 當前索引值
            const currentPage = (this as any).activeIndex + 1;
            countCal(currentPage);
          },
          // touchStart: function (event: any) {
          //   console.log(event)
          //   alert(event.touches)
          //   console.log(event.touches.length)
          //   if (event.touches.length > 1) {
          //     event.preventDefault();
          //   }
          // },
          touchMove: function (event: any) {
            if ((this as any).zoom && (this as any).zoom.scale !== 1) {
              (this as any as any).allowSlidePrev = false;
              (this as any).allowSlideNext = false;
            }
          },
          touchEnd: function () {
            if ((this as any).zoom && (this as any).zoom.scale !== 1) {
              (this as any).allowSlidePrev = true;
              (this as any).allowSlideNext = true;
            }
          },
        },
      });
    }

    let countCal = (currentPage: number) => {
      this.count = currentPage;
    };

    document.addEventListener("touchmove", function (event) {
      if (event.touches && event.touches.length == 2) {
        homePosition();
      }
    });

    let homePosition = () => {
      this.selectSwiperImages();
    };
  },
  beforeUnmount() {
    this.swiper = null;
  },
});
